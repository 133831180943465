import * as _polyfill2 from "globalthis/polyfill";
var _polyfill = _polyfill2;
try {
  if ("default" in _polyfill2) _polyfill = _polyfill2.default;
} catch (e) {}
import * as _qj2 from "qj";
var _qj = _qj2;
try {
  if ("default" in _qj2) _qj = _qj2.default;
} catch (e) {}
var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;
var exports = {};
// Generated by CoffeeScript 1.12.7
(function () {
  var Payment,
    QJ,
    cardFromNumber,
    cardFromType,
    cards,
    cursorSafeAssignValue,
    defaultFormat,
    formatBackCardNumber,
    formatBackExpiry,
    formatCardNumber,
    formatExpiry,
    formatForwardExpiry,
    formatForwardSlash,
    formatMonthExpiry,
    globalThis,
    hasTextSelected,
    luhnCheck,
    reFormatCardNumber,
    restrictCVC,
    restrictCardNumber,
    restrictCombinedExpiry,
    restrictExpiry,
    restrictMonthExpiry,
    restrictNumeric,
    restrictYearExpiry,
    setCardType,
    indexOf = [].indexOf || function (item) {
      for (var i = 0, l = (this || _global).length; i < l; i++) {
        if (i in (this || _global) && (this || _global)[i] === item) return i;
      }
      return -1;
    };
  globalThis = _polyfill();
  QJ = _qj;
  defaultFormat = /(\d{1,4})/g;
  cards = [{
    type: "amex",
    pattern: /^3[47]/,
    format: /(\d{1,4})(\d{1,6})?(\d{1,5})?/,
    length: [15],
    cvcLength: [4],
    luhn: true
  }, {
    type: "dankort",
    pattern: /^5019/,
    format: defaultFormat,
    length: [16],
    cvcLength: [3],
    luhn: true
  }, {
    type: "dinersclub",
    pattern: /^(36|38|30[0-5])/,
    format: /(\d{1,4})(\d{1,6})?(\d{1,4})?/,
    length: [14],
    cvcLength: [3],
    luhn: true
  }, {
    type: "discover",
    pattern: /^(6011|65|64[4-9]|622)/,
    format: defaultFormat,
    length: [16],
    cvcLength: [3],
    luhn: true
  }, {
    type: "elo",
    pattern: /^401178|^401179|^431274|^438935|^451416|^457393|^457631|^457632|^504175|^627780|^636297|^636369|^636368|^(506699|5067[0-6]\d|50677[0-8])|^(50900\d|5090[1-9]\d|509[1-9]\d{2})|^65003[1-3]|^(65003[5-9]|65004\d|65005[0-1])|^(65040[5-9]|6504[1-3]\d)|^(65048[5-9]|65049\d|6505[0-2]\d|65053[0-8])|^(65054[1-9]|6505[5-8]\d|65059[0-8])|^(65070\d|65071[0-8])|^65072[0-7]|^(65090[1-9]|65091\d|650920)|^(65165[2-9]|6516[6-7]\d)|^(65500\d|65501\d)|^(65502[1-9]|6550[3-4]\d|65505[0-8])|^(65092[1-9]|65097[0-8])/,
    format: defaultFormat,
    length: [16],
    cvcLength: [3],
    luhn: true
  }, {
    type: "hipercard",
    pattern: /^(384100|384140|384160|606282|637095|637568|60(?!11))/,
    format: defaultFormat,
    length: [14, 15, 16, 17, 18, 19],
    cvcLength: [3],
    luhn: true
  }, {
    type: "jcb",
    pattern: /^(308[8-9]|309[0-3]|3094[0]{4}|309[6-9]|310[0-2]|311[2-9]|3120|315[8-9]|333[7-9]|334[0-9]|35)/,
    format: defaultFormat,
    length: [16, 19],
    cvcLength: [3],
    luhn: true
  }, {
    type: "laser",
    pattern: /^(6706|6771|6709)/,
    format: defaultFormat,
    length: [16, 17, 18, 19],
    cvcLength: [3],
    luhn: true
  }, {
    type: "maestro",
    pattern: /^(50|5[6-9]|6007|6220|6304|6703|6708|6759|676[1-3])/,
    format: defaultFormat,
    length: [12, 13, 14, 15, 16, 17, 18, 19],
    cvcLength: [3],
    luhn: true
  }, {
    type: "mastercard",
    pattern: /^(5[1-5]|677189)|^(22[2-9][1-9]|2[3-6]\d{2}|27[0-1]\d|2720)/,
    format: defaultFormat,
    length: [16],
    cvcLength: [3],
    luhn: true
  }, {
    type: "mir",
    pattern: /^220[0-4][0-9][0-9]\d{10}$/,
    format: defaultFormat,
    length: [16],
    cvcLength: [3],
    luhn: true
  }, {
    type: "troy",
    pattern: /^9792/,
    format: defaultFormat,
    length: [16],
    cvcLength: [3],
    luhn: true
  }, {
    type: "unionpay",
    pattern: /^62/,
    format: defaultFormat,
    length: [16, 17, 18, 19],
    cvcLength: [3],
    luhn: false
  }, {
    type: "visaelectron",
    pattern: /^4(026|17500|405|508|844|91[37])/,
    format: defaultFormat,
    length: [16],
    cvcLength: [3],
    luhn: true
  }, {
    type: "visa",
    pattern: /^4/,
    format: defaultFormat,
    length: [13, 16],
    cvcLength: [3],
    luhn: true
  }];
  cardFromNumber = function (num) {
    var card, foundCard, j, len, match;
    num = (num + "").replace(/\D/g, "");
    foundCard = void 0;
    for (j = 0, len = cards.length; j < len; j++) {
      card = cards[j];
      if (match = num.match(card.pattern)) {
        if (!foundCard || match[0].length > foundCard[1][0].length) {
          foundCard = [card, match];
        }
      }
    }
    return foundCard && foundCard[0];
  };
  cardFromType = function (type) {
    var card, j, len;
    for (j = 0, len = cards.length; j < len; j++) {
      card = cards[j];
      if (card.type === type) {
        return card;
      }
    }
  };
  luhnCheck = function (num) {
    var digit, digits, j, len, odd, sum;
    odd = true;
    sum = 0;
    digits = (num + "").split("").reverse();
    for (j = 0, len = digits.length; j < len; j++) {
      digit = digits[j];
      digit = parseInt(digit, 10);
      if (odd = !odd) {
        digit *= 2;
      }
      if (digit > 9) {
        digit -= 9;
      }
      sum += digit;
    }
    return sum % 10 === 0;
  };
  hasTextSelected = function (target) {
    var e, ref;
    try {
      if (target.selectionStart != null && target.selectionStart !== target.selectionEnd) {
        return true;
      }
      if ((typeof document !== "undefined" && document !== null ? (ref = document.selection) != null ? ref.createRange : void 0 : void 0) != null) {
        if (document.selection.createRange().text) {
          return true;
        }
      }
    } catch (error) {
      e = error;
    }
    return false;
  };
  reFormatCardNumber = function (e) {
    return setTimeout(function (_this) {
      return function () {
        var target, value;
        target = e.target;
        value = QJ.val(target);
        value = Payment.fns.formatCardNumber(value);
        cursorSafeAssignValue(target, value);
        return QJ.trigger(target, "change");
      };
    }(this || _global));
  };
  formatCardNumber = function (maxLength) {
    return function (e) {
      var card, digit, i, j, len, length, re, target, upperLength, upperLengths, value;
      if (e.which > 0) {
        digit = String.fromCharCode(e.which);
        value = QJ.val(e.target) + digit;
      } else {
        digit = e.data;
        value = QJ.val(e.target);
      }
      if (!/^\d+$/.test(digit)) {
        return;
      }
      target = e.target;
      card = cardFromNumber(value);
      length = value.replace(/\D/g, "").length;
      upperLengths = [16];
      if (card) {
        upperLengths = card.length;
      }
      if (maxLength) {
        upperLengths = upperLengths.filter(function (x) {
          return x <= maxLength;
        });
      }
      for (i = j = 0, len = upperLengths.length; j < len; i = ++j) {
        upperLength = upperLengths[i];
        if (length >= upperLength && upperLengths[i + 1]) {
          continue;
        }
        if (length >= upperLength) {
          return;
        }
      }
      if (hasTextSelected(target)) {
        return;
      }
      if (card && card.type === "amex") {
        re = /^(\d{4}|\d{4}\s\d{6})$/;
      } else {
        re = /(?:^|\s)(\d{4})$/;
      }
      value = value.substring(0, value.length - 1);
      if (re.test(value)) {
        e.preventDefault();
        QJ.val(target, value + " " + digit);
        return QJ.trigger(target, "change");
      }
    };
  };
  formatBackCardNumber = function (e) {
    var target, value;
    target = e.target;
    value = QJ.val(target);
    if (e.meta) {
      return;
    }
    if (e.which !== 8) {
      return;
    }
    if (hasTextSelected(target)) {
      return;
    }
    if (/\d\s$/.test(value)) {
      e.preventDefault();
      QJ.val(target, value.replace(/\d\s$/, ""));
      return QJ.trigger(target, "change");
    } else if (/\s\d?$/.test(value)) {
      e.preventDefault();
      QJ.val(target, value.replace(/\s\d?$/, ""));
      return QJ.trigger(target, "change");
    }
  };
  formatExpiry = function (e) {
    var digit, target, val;
    target = e.target;
    if (e.which > 0) {
      digit = String.fromCharCode(e.which);
      val = QJ.val(target) + digit;
    } else {
      digit = e.data;
      val = QJ.val(target);
    }
    if (!/^\d+$/.test(digit)) {
      return;
    }
    if (/^\d$/.test(val) && val !== "0" && val !== "1") {
      e.preventDefault();
      QJ.val(target, "0" + val + " / ");
      return QJ.trigger(target, "change");
    } else if (/^\d\d$/.test(val)) {
      e.preventDefault();
      QJ.val(target, val + " / ");
      return QJ.trigger(target, "change");
    }
  };
  formatMonthExpiry = function (e) {
    var digit, target, val;
    digit = String.fromCharCode(e.which);
    if (!/^\d+$/.test(digit)) {
      return;
    }
    target = e.target;
    val = QJ.val(target) + digit;
    if (/^\d$/.test(val) && val !== "0" && val !== "1") {
      e.preventDefault();
      QJ.val(target, "0" + val);
      return QJ.trigger(target, "change");
    } else if (/^\d\d$/.test(val)) {
      e.preventDefault();
      QJ.val(target, "" + val);
      return QJ.trigger(target, "change");
    }
  };
  formatForwardExpiry = function (e) {
    var digit, target, val;
    digit = String.fromCharCode(e.which);
    if (!/^\d+$/.test(digit)) {
      return;
    }
    target = e.target;
    val = QJ.val(target);
    if (/^\d\d$/.test(val)) {
      QJ.val(target, val + " / ");
      return QJ.trigger(target, "change");
    }
  };
  formatForwardSlash = function (e) {
    var slash, target, val;
    slash = String.fromCharCode(e.which);
    if (slash !== "/") {
      return;
    }
    target = e.target;
    val = QJ.val(target);
    if (/^\d$/.test(val) && val !== "0") {
      QJ.val(target, "0" + val + " / ");
      return QJ.trigger(target, "change");
    }
  };
  formatBackExpiry = function (e) {
    var target, value;
    if (e.metaKey) {
      return;
    }
    target = e.target;
    value = QJ.val(target);
    if (e.which !== 8) {
      return;
    }
    if (hasTextSelected(target)) {
      return;
    }
    if (/\d(\s|\/)+$/.test(value)) {
      e.preventDefault();
      QJ.val(target, value.replace(/\d(\s|\/)*$/, ""));
      return QJ.trigger(target, "change");
    } else if (/\s\/\s?\d?$/.test(value)) {
      e.preventDefault();
      QJ.val(target, value.replace(/\s\/\s?\d?$/, ""));
      return QJ.trigger(target, "change");
    }
  };
  restrictNumeric = function (e) {
    var input;
    if (e.metaKey || e.ctrlKey) {
      return true;
    }
    if (e.which === 32) {
      return e.preventDefault();
    }
    if (e.which === 0) {
      return true;
    }
    if (e.which < 33) {
      return true;
    }
    input = String.fromCharCode(e.which);
    if (!/[\d\s]/.test(input)) {
      return e.preventDefault();
    }
  };
  restrictCardNumber = function (maxLength) {
    return function (e) {
      var card, digit, length, target, value;
      target = e.target;
      digit = String.fromCharCode(e.which);
      if (!/^\d+$/.test(digit)) {
        return;
      }
      if (hasTextSelected(target)) {
        return;
      }
      value = (QJ.val(target) + digit).replace(/\D/g, "");
      card = cardFromNumber(value);
      length = 16;
      if (card) {
        length = card.length[card.length.length - 1];
      }
      if (maxLength) {
        length = Math.min(length, maxLength);
      }
      if (!(value.length <= length)) {
        return e.preventDefault();
      }
    };
  };
  restrictExpiry = function (e, length) {
    var digit, target, value;
    target = e.target;
    digit = String.fromCharCode(e.which);
    if (!/^\d+$/.test(digit)) {
      return;
    }
    if (hasTextSelected(target)) {
      return;
    }
    value = QJ.val(target) + digit;
    value = value.replace(/\D/g, "");
    if (value.length > length) {
      return e.preventDefault();
    }
  };
  restrictCombinedExpiry = function (e) {
    return restrictExpiry(e, 6);
  };
  restrictMonthExpiry = function (e) {
    return restrictExpiry(e, 2);
  };
  restrictYearExpiry = function (e) {
    return restrictExpiry(e, 4);
  };
  restrictCVC = function (e) {
    var digit, target, val;
    target = e.target;
    digit = String.fromCharCode(e.which);
    if (!/^\d+$/.test(digit)) {
      return;
    }
    if (hasTextSelected(target)) {
      return;
    }
    val = QJ.val(target) + digit;
    if (!(val.length <= 4)) {
      return e.preventDefault();
    }
  };
  setCardType = function (e) {
    var allTypes, card, cardType, target, val;
    target = e.target;
    val = QJ.val(target);
    cardType = Payment.fns.cardType(val) || "unknown";
    if (!QJ.hasClass(target, cardType)) {
      allTypes = function () {
        var j, len, results;
        results = [];
        for (j = 0, len = cards.length; j < len; j++) {
          card = cards[j];
          results.push(card.type);
        }
        return results;
      }();
      QJ.removeClass(target, "unknown");
      QJ.removeClass(target, allTypes.join(" "));
      QJ.addClass(target, cardType);
      QJ.toggleClass(target, "identified", cardType !== "unknown");
      return QJ.trigger(target, "payment.cardType", cardType);
    }
  };
  cursorSafeAssignValue = function (target, value) {
    var selectionEnd;
    selectionEnd = target.selectionEnd;
    QJ.val(target, value);
    if (selectionEnd) {
      return target.selectionEnd = selectionEnd;
    }
  };
  Payment = function () {
    function Payment() {}
    Payment.J = QJ;
    Payment.fns = {
      cardExpiryVal: function (value) {
        var month, prefix, ref, year;
        value = value.replace(/\s/g, "");
        ref = value.split("/", 2), month = ref[0], year = ref[1];
        if ((year != null ? year.length : void 0) === 2 && /^\d+$/.test(year)) {
          prefix = new Date().getFullYear();
          prefix = prefix.toString().slice(0, 2);
          year = prefix + year;
        }
        month = parseInt(month, 10);
        year = parseInt(year, 10);
        return {
          month: month,
          year: year
        };
      },
      validateCardNumber: function (num) {
        var card, ref;
        num = (num + "").replace(/\s+|-/g, "");
        if (!/^\d+$/.test(num)) {
          return false;
        }
        card = cardFromNumber(num);
        if (!card) {
          return false;
        }
        return (ref = num.length, indexOf.call(card.length, ref) >= 0) && (card.luhn === false || luhnCheck(num));
      },
      validateCardExpiry: function (month, year) {
        var currentTime, expiry, prefix, ref, ref1;
        if (typeof month === "object" && "month" in month) {
          ref = month, month = ref.month, year = ref.year;
        } else if (typeof month === "string" && indexOf.call(month, "/") >= 0) {
          ref1 = Payment.fns.cardExpiryVal(month), month = ref1.month, year = ref1.year;
        }
        if (!(month && year)) {
          return false;
        }
        month = QJ.trim(month);
        year = QJ.trim(year);
        if (!/^\d+$/.test(month)) {
          return false;
        }
        if (!/^\d+$/.test(year)) {
          return false;
        }
        month = parseInt(month, 10);
        if (!(month && month <= 12)) {
          return false;
        }
        if (year.length === 2) {
          prefix = new Date().getFullYear();
          prefix = prefix.toString().slice(0, 2);
          year = prefix + year;
        }
        expiry = new Date(year, month);
        currentTime = new Date();
        expiry.setMonth(expiry.getMonth() - 1);
        expiry.setMonth(expiry.getMonth() + 1, 1);
        return expiry > currentTime;
      },
      validateCardCVC: function (cvc, type) {
        var ref, ref1;
        cvc = QJ.trim(cvc);
        if (!/^\d+$/.test(cvc)) {
          return false;
        }
        if (type && cardFromType(type)) {
          return ref = cvc.length, indexOf.call((ref1 = cardFromType(type)) != null ? ref1.cvcLength : void 0, ref) >= 0;
        } else {
          return cvc.length >= 3 && cvc.length <= 4;
        }
      },
      cardType: function (num) {
        var ref;
        if (!num) {
          return null;
        }
        return ((ref = cardFromNumber(num)) != null ? ref.type : void 0) || null;
      },
      formatCardNumber: function (num) {
        var card, groups, ref, upperLength;
        card = cardFromNumber(num);
        if (!card) {
          return num;
        }
        upperLength = card.length[card.length.length - 1];
        num = num.replace(/\D/g, "");
        num = num.slice(0, upperLength);
        if (card.format.global) {
          return (ref = num.match(card.format)) != null ? ref.join(" ") : void 0;
        } else {
          groups = card.format.exec(num);
          if (groups == null) {
            return;
          }
          groups.shift();
          groups = groups.filter(function (n) {
            return n;
          });
          return groups.join(" ");
        }
      }
    };
    Payment.restrictNumeric = function (el) {
      QJ.on(el, "keypress", restrictNumeric);
      return QJ.on(el, "input", restrictNumeric);
    };
    Payment.cardExpiryVal = function (el) {
      return Payment.fns.cardExpiryVal(QJ.val(el));
    };
    Payment.formatCardCVC = function (el) {
      Payment.restrictNumeric(el);
      QJ.on(el, "keypress", restrictCVC);
      QJ.on(el, "input", restrictCVC);
      return el;
    };
    Payment.formatCardExpiry = function (el) {
      var month, year;
      Payment.restrictNumeric(el);
      if (el.length && el.length === 2) {
        month = el[0], year = el[1];
        this.formatCardExpiryMultiple(month, year);
      } else {
        QJ.on(el, "keypress", restrictCombinedExpiry);
        QJ.on(el, "keypress", formatExpiry);
        QJ.on(el, "keypress", formatForwardSlash);
        QJ.on(el, "keypress", formatForwardExpiry);
        QJ.on(el, "keydown", formatBackExpiry);
        QJ.on(el, "input", formatExpiry);
      }
      return el;
    };
    Payment.formatCardExpiryMultiple = function (month, year) {
      QJ.on(month, "keypress", restrictMonthExpiry);
      QJ.on(month, "keypress", formatMonthExpiry);
      QJ.on(month, "input", formatMonthExpiry);
      QJ.on(year, "keypress", restrictYearExpiry);
      return QJ.on(year, "input", restrictYearExpiry);
    };
    Payment.formatCardNumber = function (el, maxLength) {
      Payment.restrictNumeric(el);
      QJ.on(el, "keypress", restrictCardNumber(maxLength));
      QJ.on(el, "keypress", formatCardNumber(maxLength));
      QJ.on(el, "keydown", formatBackCardNumber);
      QJ.on(el, "keyup blur", setCardType);
      QJ.on(el, "blur", formatCardNumber(maxLength));
      QJ.on(el, "paste", reFormatCardNumber);
      QJ.on(el, "input", formatCardNumber(maxLength));
      return el;
    };
    Payment.getCardArray = function () {
      return cards;
    };
    Payment.setCardArray = function (cardArray) {
      cards = cardArray;
      return true;
    };
    Payment.addToCardArray = function (cardObject) {
      return cards.push(cardObject);
    };
    Payment.removeFromCardArray = function (type) {
      var key, value;
      for (key in cards) {
        value = cards[key];
        if (value.type === type) {
          cards.splice(key, 1);
        }
      }
      return true;
    };
    return Payment;
  }();
  exports = Payment;
  globalThis.Payment = Payment;
}).call(exports);
export default exports;